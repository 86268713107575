import React from "react"
import TabNavigation from "../../../components/TabNavigation"
import LayoutBasic from "../../../components/LayoutBasic"
import BasicText from "../../../components/BasicText"
import Info2column from "../../../components/Info2column"
import OverflowFrame from "../../../components/OverflowFrame"
import css from "../stylesheets/basicInformation.module.scss"
import RubyConvert from "../../../components/RubyConvert"
import Seo from "../../../components/Seo"
import CenterContentLayout from "../../../components/CenterContentLayout"

function Kaigo(props) {
  return (
    <div>
      <Seo title={`ABOUT KAIGO | BASIC INFORMATION`} />

      <LayoutBasic lang={`easyja`}>
        <CenterContentLayout>
          <BasicText>
            <h1>BASIC INFORMATION</h1>
            <p>
              <RubyConvert>{`{[日本/にほん]は}{どんな}{[国/くに]？}{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{するには？}`}</RubyConvert>
              <br />
              <RubyConvert>{`
            {[日本/にほん]の}{[基本的/きほんてき]な}{[情報/じょうほう]や、[介護/かいご]の}{[仕事/しごと]を}{する}{ための}{4つの}{[在留資格/ざいりゅうしかく]}{などを}{[紹介/しょうかい]します。}`}</RubyConvert>
            </p>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <TabNavigation
            links={[
              { title: `ABOUT JAPAN`, link: `/basic-information/` },
              {
                title: `ABOUT KAIGO`,
                link: `/basic-information/kaigo/`,
                current: true,
              },
            ]}
          />
        </CenterContentLayout>

        <CenterContentLayout>
          <BasicText>
            <h2 className={css.BasicInfoH2}>ABOUT KAIGO</h2>
          </BasicText>
        </CenterContentLayout>

        <CenterContentLayout spFit={true}>
          <Info2column
            id={`section-1`}
            noBorder={true}
            rightWithSpPadding={true}
          >
            <div>
              <img
                src={`/assets/images/basic_info/basic_info_facility.svg`}
                alt="介護給付費"
                width={600}
                height={400}
              />
            </div>
            <div className={css.BasicInfoSummary}>
              <hr />
              <h3>
                <RubyConvert>{`{[日本/にほん]の}{[介護/かいご][施設/しせつ]と}{[介護/かいご][給付/きゅうふ][費/ひ]}`}</RubyConvert>
                <sup>※</sup>
              </h3>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>
                  <RubyConvert>{`[介護/かいご][保険/ほけん][施設/しせつ][数/すう]`}</RubyConvert>
                  :
                </dt>
                <dd>
                  <RubyConvert>{`13,409[施設/しせつ]`}</RubyConvert>
                </dd>
              </dl>
              <dl className={css.BasicInfoSummaryDlBlock}>
                <dt>
                  <RubyConvert>{`[介護/かいご]サービス[事業/じぎょう][所/しょ][数/すう]`}</RubyConvert>
                  :
                </dt>
                <dd>
                  <RubyConvert>{`363,452[事業/じぎょう][所/しょ]`}</RubyConvert>
                </dd>
              </dl>
              <RubyConvert>{`{[施設/しせつ][数/すう]は、}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホームと}{[介護/かいご][老人/ろうじん][保健/ほけん][施設/しせつ]と}{[介護/かいご][療養/りょうよう][型/がた][医療/いりょう][施設/しせつ]をあわせた[数/かず]。}{[事業/じぎょう][所/しょ][数/すう]は、}{[介護/かいご][保険/ほけん][施設/しせつ][以外/いがい]の、}{すべての}{[介護/かいご]サービス[事業/じぎょう][所/しょ]を}{あわせた}{[数/かず]です。}{これらの}{[数/かず]は}{[毎年/まいとし]}{[増/ふ]えています。}{また、[介護/かいご]サービスの}{[利用/りよう][量/りょう]を}{[表/あらわ]す}{[介護/かいご][給付/きゅうふ][費/ひ]も}{[毎年/まいとし]}{[増/ふ]えています。}`}</RubyConvert>
              <div className={`mt20`}>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      厚生労働省「平成29年介護サービス施設・事業所調査の概況」（2018年）、「介護保険事業状況報告（年報）」より。
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Info2column>
        </CenterContentLayout>

        <CenterContentLayout>
          <div id={`section-2`} className={css.BasicInfoKaigoSystem}>
            <BasicText>
              <h3>
                <RubyConvert>{`{[介護/かいご]の}{[在留/ざいりゅう][資格/しかく]}`}</RubyConvert>
              </h3>
              <h4>
                <RubyConvert>{`{4つの}{[在留/ざいりゅう][資格/しかく][制度/せいど]}`}</RubyConvert>
              </h4>
              <p>
                <RubyConvert>{`{[介護/かいご]の}{[仕事/しごと]を}{するには、4つの}{[在留/ざいりゅう][資格/しかく]が}{あります。}{2008[年/ねん]に}{はじまった}{「EPA」}（Economic Partnership Agreement{〈[経済/けいざい][連携/れんけい][協定/きょうてい]〉}）、{2017[年/ねん]に}{はじまった}{「[介護/かいご]」}と{「[技能/ぎのう][実習/じっしゅう]」、}{2019[年/ねん]に}{はじまった}{「[特定/とくてい][技能/ぎのう]」です。}`}</RubyConvert>
              </p>

              <OverflowFrame>
                <table className={css.BasicStickyTable}>
                  <thead>
                    <tr>
                      <th>
                        <i></i>
                      </th>
                      <th>
                        <i>EPA</i>
                      </th>
                      <th>
                        <i>
                          <RubyConvert>{`{[介護/かいご]}`}</RubyConvert>
                        </i>
                      </th>
                      <th>
                        <i>
                          <RubyConvert>{`{[技能/ぎのう][実習/じっしゅう]}`}</RubyConvert>
                        </i>
                      </th>
                      <th>
                        <i>
                          <RubyConvert>{`{[特定/とくてい][技能/ぎのう]}`}</RubyConvert>
                        </i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[在留/ざいりゅう][資格/しかく]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`「{[特定/とくてい][活動/かつどう]}」`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`「{[介護/かいご]}」`}</RubyConvert>{" "}
                          <sup>※4</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`「{[技能/ぎのう][実習/じっしゅう]1〜3[号/ごう]}」`}</RubyConvert>{" "}
                          <sup>※7</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`「{[特定/とくてい][技能/ぎのう]1[号/ごう]}」`}</RubyConvert>{" "}
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[制度/せいど]の}{[目的/もくてき]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{[介護/かいご][福祉士/ふくしし]の}{[国家/こっか][資格/しかく]を}{[取/と]りたい}{[人/ひと]を}{[受/う]け[入/い]れる}／{[日本/にほん]が}{ほかの}{[国/くに]と}{[協力/きょうりょく]しあう}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[専門的/せんもんてき]な}{[分野/ぶんや]や}{[技術的/ぎじゅつてき]な}{[分野/ぶんや]に}{[外国人/がいこくじん]の}{[労働者/ろうどうしゃ]を}{[受/う]け[入/い]れる}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[日本/にほん]の}{[技能/ぎのう]を}{ほかの}{[国/くに]に}{[伝/つた]える}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[専門性/せんもんせい]や}{[技能/ぎのう]を}{もっている}{[外国人/がいこくじん]を}{[受/う]け[入/い]れて、[労働者/ろうどうしゃ]を}{[増/ふ]やす}`}</RubyConvert>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[実施/じっし]している}{[国/くに]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{インドネシア・フィリピン・}`}</RubyConvert>
                          <br />
                          <RubyConvert>{`{ベトナム}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[制限/せいげん]なし}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[制限/せいげん]なし}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[制限/せいげん]なし}`}</RubyConvert>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[日本/にほん]に}{[滞在/たいざい]できる}{[期間/きかん]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{4[年/ねん]（[資格/しかく]を[取/と]れば[制限/せいげん]なし）}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[制限/せいげん]なし}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[最長/さいちょう]5[年/ねん]}`}</RubyConvert>
                          <sup>※8</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[最長/さいちょう]5[年/ねん]}`}</RubyConvert>
                          <sup>※8</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[一緒/いっしょ]に}{[行/い]ける}{[家族/かぞく]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{[家族/かぞく]（[配偶者/はいぐうしゃ]・[子/こ]ども）}`}</RubyConvert>
                          <sup>※1</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[家族/かぞく]（[配偶者/はいぐうしゃ]・[子/こ]ども）}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[日本語/にほんご][能力/のうりょく]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          N3 <sup>※2</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          N2 <sup>※5</sup>
                        </i>
                      </td>
                      <td>
                        <i>N4</i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{CFER A2、}{[介護/かいご]の[言葉/ことば]がわかる}`}</RubyConvert>
                          <sup>※10</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[母国/ぼこく]での}{[介護/かいご][知識/ちしき]・[経験/けいけん]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{[看護/かんご][学校/がっこう]の}{[卒業生/そつぎょうせい]} or {[母国/ぼこく]で}{[介護士/かいごし]}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{` {[個人/こじん]による}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[監理/かんり][団体/だんたい]による}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[一定/いってい]の[知識/ちしき]・[技能/ぎのう]がある}`}</RubyConvert>
                          <sup>※11</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[介護/かいご][福祉士/ふくしし][国家/こっか][試験/しけん]の}{[受験/じゅけん][義務/ぎむ]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          あり <sup>※3</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          あり <sup>※6</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          なし <sup>※9</sup>
                        </i>
                      </td>
                      <td>
                        <i>
                          なし <sup>※9</sup>
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <i>
                          <RubyConvert>{`{[受/う]け[入/い]れ[調整/ちょうせい]・[支援/しえん][機関/きかん]}`}</RubyConvert>
                        </i>
                      </th>
                      <td>
                        <i>
                          <RubyConvert>{`{JICWELS（[公益/こうえき][社団/しゃだん][法人/ほうじん][国際/こくさい][厚生/こうせい][事業団/じぎょうだん]）}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>なし</i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[監理/かんり][団体/だんたい]}`}</RubyConvert>
                        </i>
                      </td>
                      <td>
                        <i>
                          <RubyConvert>{`{[登録/とうろく][支援/しえん][機関/きかん]}`}</RubyConvert>
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>介護福祉士の 国家資格を 取れた 場合。</span>
                </li>
                <li>
                  <i>※2</i>
                  <span>
                    フィリピンは N5以上、インドネシアは N4以上、ベトナムは
                    N3以上。
                  </span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    不合格でも よい 点数を 取れていれば、1年 長く 日本に いる
                    ことが できます。
                  </span>
                </li>
                <li>
                  <i>※4</i>
                  <span>介護福祉士の 資格を 取る 前は、「留学」。</span>
                </li>
                <li>
                  <i>※5</i>
                  <span>N2以上が 必要な 養成学校が あります。</span>
                </li>
                <li>
                  <i>※6</i>
                  <span>
                    2017年4月～2022年3月までに 卒業した 人は、介護福祉士の
                    国家資格が 取れます。ただし、卒業してから 5年の あいだに
                    国家試験に 合格するか、卒業してから 5年間 介護の 仕事を する
                    ことが 必要です。そう しないと 資格が
                    なくなります。たとえば、2020年3月に 卒業した
                    人は、2025年までに 国家試験に
                    合格するか、2020年4月～2025年4月まで 介護の 仕事を すれば
                    よいです。
                  </span>
                </li>
                <li>
                  <i>※7</i>
                  <span>
                    1年目は「技能実習1号」、2～3年目は「技能実習2号」、4～5年目は「技能実習3号」。
                  </span>
                </li>
                <li>
                  <i>※8</i>
                  <span>
                    介護福祉士の 資格を 取れば、在留資格を「介護」に
                    変えて、期間の 制限を なくす ことが できます。3年目まで
                    終わった 技能実習生は、「特定技能1号」の 試験を 受けなくて
                    よいです（在留資格を 変えた 場合、技能実習の 5年と
                    特定技能の 5年を あわせて 10年まで いる ことが できます）。
                  </span>
                </li>
                <li>
                  <i>※9</i>
                  <span>
                    介護福祉士の 資格を 取れば、在留資格を「介護」に
                    変えられます。
                  </span>
                </li>
                <li>
                  <i>※10</i>
                  <span>
                    独立行政法人
                    国際交流基金の「日本語基礎テスト」と、厚生労働省の「介護日本語評価試験」に
                    合格する ことが 必要です。日本語能力が N4以上の
                    場合は「日本語基礎テスト」は 受けなくて よいです。
                  </span>
                </li>
                <li>
                  <i>※11</i>
                  <span>
                    厚生労働省の「介護技能評価試験」に 合格する ことが
                    必要です。
                  </span>
                </li>
              </ul>
              <a
                className={`mb60`}
                href={`/assets/images/basic_info/basic_info_kaigo_4type_eja.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (115KB)
              </a>

              <h4>
                <RubyConvert>{`{[働/はたら]くまでの}{[流/なが]れ}`}</RubyConvert>
              </h4>
              <p>
                <RubyConvert>{`{[在留/ざいりゅう][資格/しかく]の}{[種類/しゅるい]によって、[介護/かいご]の}{[施設/しせつ]で}{[働/はたら]くまでの}{[流/なが]れが}{[違/ちが]います。}{「EPA」と}{「[介護/かいご]」は、}{[介護/かいご][福祉/ふくし][士/し]の}{[国家/こっか][資格/しかく]を}{[取/と]れば}{ずっと}{[日本/にほん]で}{[働/はたら]く}{ことが}{できます。}{「[技能/ぎのう][実習/じっしゅう]」と}{「[特定/とくてい][技能/ぎのう]」は}{[自分/じぶん]の}{[国/くに]に}{[帰/かえ]って、[日本/にほん]で}{[身/み]につけた}{[技能/ぎのう]を}{いかす}{ことが}{できます。}`}</RubyConvert>
              </p>

              <OverflowFrame>
                <img
                  src={`/assets/images/basic_info/basic_info_kaigo_flow_eja.png`}
                  alt="働くまでの流れ"
                />
              </OverflowFrame>

              <ul className={`notes`}>
                <li>
                  <i>※1</i>
                  <span>
                    2017年4月～2022年3月までに 卒業した 人は、介護福祉士の
                    国家資格が 取れます。ただし、卒業してから 5年の あいだに
                    国家試験に 合格するか、卒業してから 5年間 介護の 仕事を する
                    ことが 必要です。そう しないと 資格が
                    なくなります。たとえば、2020年3月に 卒業した
                    人は、2025年までに 国家試験に
                    合格するか、2020年4月～2025年4月まで 介護の 仕事を すれば
                    よいです。
                  </span>
                </li>
                <li>
                  <i>※2</i>
                  <span>
                    点線の なかの「働くコース」は、これから できる
                    予定です。いまは まだ ありません。
                  </span>
                </li>
                <li>
                  <i>※3</i>
                  <span>
                    4年間、EPA介護福祉士候補者として 勉強して、働いた
                    人は、特定技能1号に なる ために 必要な 技能試験や
                    日本語能力試験を 受けなくても よいです。
                  </span>
                </li>
              </ul>
              <a
                href={`/assets/images/basic_info/basic_info_kaigo_flow_eja.pdf`}
                target={`_blank`}
              >
                PDF DOWNLOAD (96KB)
              </a>
            </BasicText>
          </div>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export default Kaigo
