import React from "react"
import { Link } from "gatsby"
import css from "./stylesheets/TabNavigation.module.scss"

export default props => {
  return (
    <div className={css.base}>
      <ul className={css.tab}>
        {props.links.map((data, index) => (
          <li className={css.tabItem} key={`content_item_${index}`}>
            {data.current ? (
              <Link to={data.link} className={css.current}>
                {data.title}
              </Link>
            ) : (
              <Link to={data.link}>{data.title}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
